import { axiosInstanceWithAuth } from '@/utils/utils'
import { ref } from 'vue'

const getTopElements = () => {
  const topElements = ref([])
  const error = ref(null)

  const load = async () => {
    try {
      let res = await axiosInstanceWithAuth.get('/api/topographic-element-4')
      topElements.value = res.data.data.map(topographicElement4 => {
        return { value: topographicElement4.topographic_element_id, label: topographicElement4.name }
      })
    } catch (err) {
      error.value = err
    }
  }

  return { topElements, load, error }
}

export default getTopElements