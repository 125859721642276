<template>
  <label class="input-with-label v-input bg-primary-color" :class="{ disabled: disabled }">
    {{ label }}
    <textarea v-if="type == 'textarea'" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
      :disabled="disabled"></textarea>
    <!-- Hay que pasarle array de string por :items -->
    <!-- Tiene otro prop (isAsync) que si es true aparece un mensaje de "Loading...", por si lo quieres implementar -->
    <AutocompleteSelect v-else-if="type == 'select'" :createNewItem="createNewItem" :options="options" :createUrl="createUrl" :value="modelValue"
    :disableTyping="disableTyping"
      @input="$emit('update:modelValue', $event.value)" :disabled="disabled" />
    <input v-else :type="type" :disabled="disabled" :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)">

    <VIcon v-if="type == 'select'" :src="require('@/assets/icons/simple-arrow.svg')" class="arrow" 
      :size="15" />
  </label>
</template>
<script>
import AutocompleteSelect from './AutocompleteSelect.vue';

export default {
  props: {
    type: { type: String, default: "text" },
    label: { type: String },
    createUrl: { type: String },
    createNewItem: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    modelValue: {},
    disableTyping: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  components: { AutocompleteSelect }
}
</script>
<style scoped>
label {
  position: relative;
  display: flex;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
  padding: 10px;
  gap: 5px;
  border: 1px solid var(--base-mid-color);
  line-height: 20px;
}

input {
  background: none;
}

textarea {
  width: 100%;
  border: none;
  outline: none;
  line-height: 1.3rem;
  resize: vertical;
  min-height: 90px;
  max-height: 270px;
}

.input-with-label.medium {
  height: 42px;
}

.disabled,
.disabled * {
  background-color: var(--base-light-color);
  cursor: not-allowed;
}

.arrow {
  transition: all .4s ease;
  padding: 0 8px;
}

.arrowOpen {
  transition-delay: .1s;
  transform: rotateX(180deg);
}
</style>