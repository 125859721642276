<template>
  <div class="lateral main-menu flex-col jc-between" @blur="closeMenu" :tabindex="-1"
    :class="{ open: uxStore.mainMenu.open }">
    <div class="flex-col gap-20">
      <img src="@/assets/pompei_logo.png" alt="">
      <div class="flex-row jc-between">
        <div class="flex-col">
          <div class="avatar jc-between">
            <VIcon :src="require('@/assets/icons/avatar.svg')" />
            <div class="fullname">
              <p>{{ authStore.user.name }}</p>
              <p>{{ authStore.user.surname }}</p>
            </div>
          </div>
          <b class="date">{{ currentDate }}</b>
        </div>
        <div class="flex-col">
          <div @click="uxStore.mainMenu.open = !uxStore.mainMenu.open" class="action-icon">
            <VIcon :src="require('@/assets/icons/arrow.svg')" :class="{ opened: uxStore.mainMenu.open }" />
          </div>
          <VIcon class="hint-icon" @click="uxStore.mainMenu.open = !uxStore.mainMenu.open"
            :src="require('@/assets/icons/text.svg')" />
        </div>
      </div>
    </div>
    <div class="gap-15 flex-col group">
      <div class="flex-row gap-20 jc-between" @click.stop="() => openRoute('manutenzione')">
        <VButton :class="'menu small rounded dark-color ' + (currentRoute.includes('manutenzione') ? 'active' : '')"
          label="Manutenzione" />
        <VIcon :class="'menu dark-color ' + (currentRoute.includes('manutenzione') ? 'active' : '')"
          :src="require('@/assets/icons/helmet.svg')" />
      </div>
      <div class="flex-row gap-20 jc-between" @click="() => openRoute('map')">
        <VButton :class="'menu small rounded dark-color ' + (currentRoute.includes('cartografia') ? 'active' : '')"
          label="Cartografia" />
        <VIcon :class="'menu dark-color ' + (currentRoute.includes('cartografia') ? 'active' : '')"
          :src="require('@/assets/icons/geo-pin.svg')" />
      </div>
      <!-- <div class="flex-row gap-20 jc-between" @click="() => openRoute('calendario')">
        <VButton :class="'menu small rounded dark-color ' + (currentRoute == 'calendario' ? 'active' : '')"
          label="Calendario" />
        <VIcon :class="'menu dark-color ' + (currentRoute == 'calendario' ? 'active' : '')"
          :src="require('@/assets/icons/calendar.svg')" />
      </div> -->
      <div class="flex-row gap-20 jc-between" @click="() => openRoute('gestione')">
        <VButton :class="'menu small rounded dark-color ' + (currentRoute.includes('gestione') ? 'active' : '')"
          label="Gestione" />
        <VIcon :class="'menu dark-color ' + (currentRoute.includes('gestione') ? 'active' : '')"
          :src="require('@/assets/icons/gear.svg')" />
      </div>
      <div class="flex-row gap-20 jc-between" @click="() => openRoute('supporto')">
        <VButton :class="'menu small rounded dark-color ' + (currentRoute.includes('supporto') ? 'active' : '')"
          label="Supporto" />
        <VIcon :class="'menu dark-color ' + (currentRoute.includes('supporto') ? 'active' : '')"
          :src="require('@/assets/icons/headphones.svg')" />
      </div>
      <div class="flex-row gap-20 jc-between">
        <VButton :class="'menu small rounded dark-color ' + (currentRoute.includes('supporto') ? 'active' : '')"
          label="Statistiche" />
        <VIcon :class="'menu dark-color ' + (currentRoute.includes('supporto') ? 'active' : '')"
          :src="require('@/assets/icons/grahp.svg')" />
      </div>
    </div>
    <!--<div class="gap-15 flex-col jc-between group">
      <VButton class="menu small external" label="SMI" @click="() => openRoute('smi')" />
      <VButton class="menu small external" label="SIPOMPEII" @click="() => openRoute('sipompeii')" />
      <VButton class="menu small external" label="SIAV" @click="() => openRoute('siav')" />
      <VButton class="menu small external" label="Tolomeo" @click="() => openRoute('tolomeo')" />
    </div>-->

    <div class="flex-row jc-between group">
      <VButton class="menu small solid jc-between" :icon="require('@/assets/icons/exit.svg')" label="Esci" :reverse="true"
        @click.stop="logout" />
      <!-- <VIcon :src="require('@/assets/icons/help.svg')" /> -->
    </div>
    <div class="development-notice">
      Il sistema è in fase di sviluppo
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useUXStore } from '@/stores/ux';
const router = useRouter()

const authStore = useAuthStore()
const uxStore = useUXStore()

// manage menu buttons click
const openRoute = (route) => {
  router.push({ name: route })
}

const closeMenu = (event) => {
  // avoid closing menu when clicking on a child element
  if (event.currentTarget.contains(event.relatedTarget)) {
    // maintain focus on menu
    event.currentTarget.focus()
    return
  }

  // wait 100ms to avoid closing menu when clicking on another button
  setTimeout(() => {
    uxStore.mainMenu.open = false
  }, 100)
}

const logout = async () => {
  await authStore.logout()
}


const currentRoute = computed(() => {
  return router.currentRoute.value.fullPath
})

let currentDate = new Date().toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
</script>
<style scoped>
.main-menu {
  --icon-color: var(--primary-color);
  min-width: 300px;
  background-color: var(--base-dark-color);
  margin-left: -240px;
  padding: 30px 11.5px 90px 35px;
  color: var(--primary-color);
  z-index: 1;
}

.main-menu.open {
  margin-left: 0px;
  transition: all .4s ease;
}

.main-menu img {
  width: 130px;
}

.group {
  margin-left: 35px;
}


.avatar {
  background-color: rgba(84, 81, 85, 0.33);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  height: 58px;
  width: 195px;
  padding-left: 10px;
  padding: 10px 15px 10px 10px;
  color: var(--primary-color);
}

.avatar .fullname {
  flex-direction: column;
}

.avatar .fullname p {
  text-align: right;
  max-width: 130px;
}

.date {
  margin: 10px 0;
  text-align: right;
}

.main-menu>div:last-child .v-button {
  color: var(--secondary-color);
}

.v-icon.opened {
  /* transform: rotateY(180deg); */
  transform: rotateY(180deg);
  transition: all .3s ease .1s;
}


@media only screen and (max-height: 672px) {
  .main-menu {
    padding: 20px 11.5px 20px 35px;
  }
}

.development-notice {
  padding: 20px;
  color: #ffffff; /* Cambia questo in base al tuo design */
  font-weight: bold;
  text-align: center;
}
</style>