<template>
  <div class="speditivo-form flex-col gap-20">
    <div class="ai-start jc-between">
      <div class="speditivo-title">
        <VIcon :src="require('@/assets/icons/form.svg')" />
        <h2>Nuovo monitoraggio<br />speditivo</h2>
      </div>
      <VButton :icon="require('@/assets/icons/cross.svg')" @click="close" />
    </div>
    <form v-if="!loading" class="form-box bg-primary-color grid gap-30" @submit.prevent="submit">
      <div class="grid grid-2-col gap-10">
        <InputWithLabel class="medium ai-center rounded" label="Data:" type="date" disabled v-model="date" name="date" />
        <InputWithLabel class="medium ai-center rounded" label="Ora:" type="time" disabled v-model="time" name="time" />
        <InputWithLabel class="medium ai-center grid-col-span" label="Progetto di riferimento:" type="select"
          :options="projects" v-model="project_id" name="project_id" :createUrl="'/api/project'" />
        <InputWithLabel class="grid-col-span flex-col ai-start" label="Osservazioni:" type="textarea" name="observations"
          v-model="observations" />
        <InputWithLabel class="medium ai-center grid-col-span rounded" label="Segnalatore:" type="text" disabled
          v-model="authStore.user.full_name" />
        <UploadFiles :maxSize="5" accept="png,jpg,jpeg" class="grid-col-span rounded" name="images"
          :existingFiles="existingFiles" @remove="removeFile" />
      </div>
      <div class="flex-col gap-10 jc-between">
        <div class="flex-col gap-10">
          <VMap geolocate @location="setPosition" :customLayers="MAP_LAYERS" :center="MAP_CENTER" :currentLayer="0" />
        </div>
        <div class="flex-col gap-10">
          <StatusLabel class="rounded" text="Priorità: " :options="priorities" name="priority_id"
            v-if="priorities.length > 0" v-model="priority_id" />
          <div class="buttons grid grid-2-col gap-10" :style="{ opacity: isSubmitting ? 0.6 : 1 }">
            <VButton class="rounded" label="Cancella" @click="close" :disabled="isSubmitting" />
            <VButton type="submit" class="rounded" label="Salva" :disabled="isSubmitting" />
          </div>
        </div>
      </div>
    </form>
    <VLottie class="loading" v-if="loading" ref="animation" :src="require('@/assets/icons/loading.json')" :autoplay="true"
      :loop="true" />
    <!--<Teleport to="#container">
      <RefTopPopUp v-if="showRefTopSelector" @close="showRefTopSelector = false" @save="setTopographicElement4" />
    </Teleport>-->
  </div>
</template>
<script>
import { useUXStore } from '@/stores/ux'
import { onMounted, ref } from 'vue'
import InputWithLabel from '../InputWithLabel.vue'
import UploadFiles from '../UploadFiles.vue'
import StatusLabel from '../StatusLabel.vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import useMonitoring from '@/services/composables/useMonitoring'
import getMonitoring from '@/services/composables/getMonitoring'
import getTable from '@/services/composables/getTable'
import getTopElements from '@/services/composables/getTopElements'
import getQuickMonitoringPriorities from '@/services/composables/getQuickMonitoringPriorities'
// eslint-disable-next-line no-unused-vars
import { MAP_CENTER, MAP_LAYERS } from '@/utils/polygon'

export default {
  components: { InputWithLabel, UploadFiles, StatusLabel },
  emits: ['close', 'submit'],
  setup(props, { emit }) {
    const loading = ref(true)
    const uxstore = useUXStore()
    const router = useRouter()
    const { addMonitoring } = useMonitoring('quick-monitoring')
    const { values: projects, load: loadProjects } = getTable('project', 'projects')
    const { topElements, load: loadTopElements } = getTopElements()
    const { quickMonitoringPriorities: priorities, load: loadPriorities } = getQuickMonitoringPriorities()
    const showRefTopSelector = ref(false)


    let authStore = useAuthStore()
    let date = new Date().toISOString().substr(0, 10)
    let time = new Date().toISOString().substr(11, 5)
    const project_id = ref(null)
    const priority_id = ref(null)
    const observations = ref('')
    const deletedImages = ref([])
    const position = ref(null)
    const isSubmitting = ref(false)

    let existingFiles = ref([])

    const close = () => {
      if (router.currentRoute.value.name === 'monitoraggio-speditivo') {
        // if there is a back path, go back to it
        if (router.currentRoute.value.query.back) {
          router.push(router.currentRoute.value.query.back)
        } else {
          router.push({
            path: router.currentRoute.value.meta.onClosePath
          })
        }
      } else {
        emit('close')
      }
    }

    const setPosition = (pos) => {
      console.log(pos)
      position.value = pos
    }

    onMounted(async () => {
      await loadProjects()
      await loadTopElements()
      await loadPriorities()

      // the existing monitoring is initialized
      if (router.currentRoute.value.params.id) {
        const { monitoring, load } = getMonitoring('quick-monitoring', router.currentRoute.value.params.id)
        await load()
        project_id.value = monitoring.value.project_id
        position.value = monitoring.value.position
        observations.value = monitoring.value.observations
        existingFiles.value = monitoring.value.files
        priority_id.value = monitoring.value.quick_monitoring_priority_id
      } else {
        priority_id.value = priorities.value[0].value
      }

      loading.value = false
    })

    const removeFile = (file) => {
      deletedImages.value.push(file.id)

      existingFiles.value = existingFiles.value.filter(existingFile => {
        return existingFile.name !== file.name
      })
    }

    const submit = () => {
      isSubmitting.value = true;
      // get files input
      let files = document.querySelector('input[type="file"][name="images"]').files

      // create the form data object that will be passed to the request
      let formData = {
        project_id: project_id.value,
        observations: observations.value,
        images: files,
        position: JSON.stringify(position.value),
        deletedImages: deletedImages.value,
        priority_id: priority_id.value
      }

      if (router.currentRoute.value.params.id) {
        formData.id = router.currentRoute.value.params.id
      }

      addMonitoring(formData, 'quick-monitoring').then((id) => {
        this.$emit('submit', '/manutenzione/monitoraggio/puntuale/' + id + '/mostra')
      }).finally(() => {
        isSubmitting.value = false
      })
    }

    return {
      close, submit, projects, topElements, date, time, project_id, authStore, uxstore, observations, existingFiles, deletedImages, removeFile, loading, showRefTopSelector,
      setPosition, priorities, priority_id, isSubmitting
    }
  }

}
</script>
<style>
.speditivo-form {
  position: absolute;
  z-index: 4;
  height: 100%;
  width: 100%;
  background-color: var(--base-light-color);
  padding: 40px 60px;
  font-size: .875rem;
}

.speditivo-title {
  gap: 10px;
  text-align: left;
  margin-left: 25px;
}

.form-box {
  grid-template-columns: 7fr 3fr;
  padding: 30px;
}

.form-box>div:first-child {
  grid-template-rows: repeat(5, max-content);
}

.speditivo-form .map {
  height: 250px !important;
}

.ref-top-details {
  text-align: left;
  background-color: var(--base-light-color);
  padding: 15px;
}

/* .buttons {
  position: absolute;
  bottom: 50px;
  right: 50px;
} */

.buttons .v-button {
  padding: 13px 15px;
  background-color: var(--base-dark-color);
  color: var(--primary-color);
  justify-content: center;
}

.v-button.ref-topografic {
  border: 1px solid var(--base-mid-color);
}

.v-button.ref-topografic .label {
  color: var(--secondary-color);
  font-weight: 400;
  padding-left: 10px
}

.v-button.ref-topografic .v-icon {
  background-color: var(--base-dark-color);
  --icon-color: white;
  height: 40px;
  width: 40px;
  justify-content: center;
}
</style>