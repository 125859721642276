import { ref } from 'vue'
import { axiosInstanceWithAuth } from '@/utils/utils'

// get single monitoring by TYPE (speditivo, puntuale...) and ID of the monitoring
const getMonitoring = (type, id) => {
  const monitoring = ref(null)
  const error = ref(null)

  const load = async () => {
    try {
      console.log('getMonitoring: ', type, id);
      let res = await axiosInstanceWithAuth.get(`/api/${type}/${id}`)
      monitoring.value = res.data.data
      console.log('Monitoring: ', monitoring.value);
    } catch (err) {
      error.value = err
      console.log('Error fetch monitoring: ', err);
    }
  }

  return { monitoring, error, load }
}

export default getMonitoring