<template>
  <div class="right-menu lateral" :style="{ marginRight: getWidth }">
    <div class="menu-bar flex-col" ref="bar">
      <div class="action-icon" @click="showRightMenu = !showRightMenu">
        <VIcon :src="require('@/assets/icons/arrow.svg')" :class="{ opened: showRightMenu }" />
      </div>
      <VIcon class="hint-icon" @click="showRightMenu = !showRightMenu" :src="require('@/assets/icons/geo-pin.svg')"
        :size="18" />
    </div>
    <div class="content" :style="{ width: size }" ref="content">
      <slot></slot>
    </div>
  </div>
</template>
<script setup>
import { useUXStore } from '@/stores/ux';
import { ref, computed, onUpdated } from 'vue'
const showRightMenu = ref(false)
const content = ref(null)
const uxstore = useUXStore()
const size = ref('35vw')

onUpdated(() => {
  if(uxstore.rightMenu.dynamicComponent == 'RightMenuMap') {
    size.value = '35vw'
  } else {
    size.value = '250px'
  }
})
const bar = ref(null)

const getWidth = computed(() => {
  if (showRightMenu.value) {
    return '0px'
  } else {
    // return '-' + content.value?.clientWidth + 'px'
    return '-' + size.value
  }
})

</script>
<style scoped>
.right-menu {
  background-color: var(--base-mid-color);
  grid-template-columns: 60 auto;
  z-index: 1;
}

.content {
  overflow: hidden;
}

.menu-bar {
  --icon-color: white;
  margin-top: 110px;
  padding: 0 10px;
}

.menu-bar .v-icon:first-of-type {
  transform: rotate(180deg);
}

.v-icon.opened {
  transition: all .3s ease;
  transform: rotate(0deg) !important;
}
</style>