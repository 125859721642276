import { useToast } from "vue-toastification";
import { onBeforeMount } from "vue";
import { blobToFile } from "@/utils/utils";

export const notificationSettings = {
  position: "bottom-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
};
const toast = useToast();

async function getBlobsFromDB(db, blobType, blobIds) {
  const blobs = [];

  for (const blobId of blobIds) {
    try {
      const { blob } = await db.get(blobType, blobId);
      const file = blobToFile(blob, `file-${blobId}.jpg`); // change the file extension based on the actual file type
      blobs.push(file);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  return blobs;
}

export async function syncMonitorings(db, addMonitoring) {
  const punctualMonitorings = await db.getAll('punctual-monitoring');
  let syncSuccess = true; // variable to keep track if synchronization was successful

  if (punctualMonitorings.length > 0) {
    for (const punctualMonitoring of punctualMonitorings) {
      let data = JSON.parse(punctualMonitoring.data);

      await Promise.all(data.degradations.map(async (degradation) => {
        degradation.files = await getBlobsFromDB(db, 'photoBlobs', degradation.files);
        degradation.drawings = await getBlobsFromDB(db, 'drawingBlobs', degradation.drawings);
      }));

      try {
        await addMonitoring(data, 'punctual-monitoring');
        await db.delete("punctual-monitoring", punctualMonitoring.id);
      } catch (err) {
        console.log(err);
        toast(`Errore durante la sincronizzazione: ${err.message}`, notificationSettings);
        syncSuccess = false; // sync failed
      }
    }

    // if sync was successful, clear all the monitorings and blobs from the indexedDB
    if (syncSuccess) {
      await Promise.all([
        db.clear('punctual-monitoring'),
        db.clear('photoBlobs'),
        db.clear('drawingBlobs')
      ]);

      // show notification
      toast('I monitoraggi creati offline sono stati sincronizzati con il server', notificationSettings);
    }
  }
}

export default function useConnectionManager() {
  onBeforeMount(() => {
    window.addEventListener("online", async () => {
      toast('La connessione è stata ripristinata', notificationSettings);
    });

    window.addEventListener("offline", () => {
      console.log("offline");
      toast('La connessione è stata persa', notificationSettings);
    });
  });
}