<template>
    <ShowMonitoraggioTemplate type="punctual" icon="punctual" :color="'#' + monitoring.color" :title="monitoring?.name"
        v-if="monitoring" :subtitle="'REF TOP. ' + monitoring.topographic_element4?.code" :date="monitoring?.date"
        :time="monitoring?.time" :closePath="router.options.history.state.back" :validated="monitoring.monitoring.validated !== 0">
        <template #content>
            <div class="gap-5 flex-col" v-if="monitoring">
                <p><strong>Tipo di monitoraggio: </strong>Puntuale</p>
                <p><strong>TIPOLOGIA REFERENTE TOPOGRAFICO: </strong>{{
                    monitoring.topographic_element4.type.name }}</p>
                <p><strong>REFERENTE TOPOGRAFICO: </strong>{{ monitoring.topographic_element4.code }}</p>
                <p><strong>PROGETTO DI RIFERIMENTO: </strong>{{ monitoring.project.name }}</p>
                <p><strong>SEGNALATORE: </strong>{{ monitoring.user.full_name }}</p>
                <p><strong>OSSERVAZIONI: </strong>{{ monitoring.observations || '-' }}</p>
            </div>
            <div class="flex-col gap-5" v-if="monitoring">
                <p><strong>INDICI:</strong></p>
                <div v-for="specificElement in monitoring.specificElements" :key="specificElement.id"
                    class="item-modal flex-col active">
                    <div class="ai-center grid grid-2-col">
                        <p>{{ specificElement.name }}</p>
                        <div class="flex-row gap-10">
                            <StatusTag label="IA"
                                :color="'#' + monitoring.ia.find(i => i.specific_element_id == specificElement.id)?.color"
                                :border="true" :height="30" />
                            <StatusTag label="IFR"
                                :color="'#' + monitoring.ifr.find(i => i.specific_element_id == specificElement.id)?.color"
                                :border="true" :height="30" />
                            <StatusTag
                                :label="'DR ' + monitoring.dr.find(i => i.specific_element_id == specificElement.id)?.sum.toFixed(1)"
                                :border="true" :height="30" :width="85" />
                        </div>
                    </div>
                </div>
            </div>
            <hr v-if="monitoring && monitoring.degradations.length > 0" />
            <div class="flex-col gap-5" v-if="monitoring && monitoring.degradations.length > 0">
                <p><strong>Degradi: </strong></p>
                <!--<VInput type="text" class="medium solid" :icon="require('@/assets/icons/search.svg')" />-->
            </div>
            <div class="flex-col gap-10 deg-list" v-if="monitoring">
                <DegradoItemList @open="openDegrado(item.id)" v-for="(item, index) in monitoring.degradations"
                    :key="index" :code="item.degradation_type.code" :title="item.degradation_type.name" />
            </div>
            <Teleport to="#container">
                <Transition name="fade">
                    <DegradoPopup @close="showDegrado = false" v-if="showDegrado && degradation"
                        :degradation="degradation.data" />
                </Transition>
            </Teleport>
        </template>

        <template #map>
            <div class=" flex-col" v-if="monitoring">
                <p><strong>REGIONE: </strong>{{ monitoring.topographic_element4.environment?.unity?.insula?.region?.name
                    || '-' }}</p>
                <p><strong>INSULA: </strong>{{ monitoring.topographic_element4.environment?.unity?.insula?.name || '-'
                    }}</p>
                <p><strong>UNITÀ: </strong>{{ monitoring.topographic_element4.environment?.unity?.name || '-' }}</p>
                <p><strong>AMBIENTE: </strong>{{ monitoring.topographic_element4.environment?.name || '-' }}</p>
            </div>
            <VMap :polygons="polygons" zoom-control :customLayers="MAP_LAYERS" :leafletConfig="{ maxZoom: 23 }"
            :zoomDuration="1"
                :center="MAP_CENTER" />
        </template>

        <!-- <template #buttons>
            <VButton class="medium rounded action mid" label="Risolvi" />
        </template> -->
    </ShowMonitoraggioTemplate>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import ShowMonitoraggioTemplate from './ShowMonitoraggioTemplate.vue';
import DegradoItemList from '../DegradoItemList.vue';
import StatusTag from '../StatusTag.vue';
import getMonitoring from '@/services/composables/getMonitoring';
import DegradoPopup from '../DegradoPopup.vue';
import { axiosInstanceWithAuth } from '@/utils/utils';
import { MAP_CENTER, MAP_LAYERS } from '@/utils/polygon';

const router = useRouter();
const degradation = ref(null);
const showDegrado = ref(false)
const { monitoring, load } = getMonitoring('punctual-monitoring', router.currentRoute.value.params.id);

onMounted(() => {
    load().then(() => {
    })
});

const polygons = computed(() => {
    return monitoring.value ? [{
        focus: true,
        style: {
            color: '#' + monitoring.value.color,
            weight: 3,
            opacity: 1,
            fillColor: '#' + monitoring.value.color,
            fillOpacity: 0.2
        },
        polygon: monitoring.value.topographic_element4.transformed_polygon,
    }] : []
})

const openDegrado = (id) => {
    // set degradation to null to avoid showing old data
    degradation.value = null;

    showDegrado.value = true

    axiosInstanceWithAuth.get('/api/degradation/' + id)
        .then((response) => {
            degradation.value = response.data
        })
}
</script>

<style scoped>
.deg-list {
    min-height: 300px;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
    padding-right: 10px;
}
</style>