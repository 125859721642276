import { openDB } from "idb";
import { booleanPointInPolygon, centroid, distance } from "@turf/turf";
import { geojsonToTurf } from "@/utils/polygon";
import { axiosInstanceWithAuth } from "@/utils/utils";

export async function getNearestTopographicElement4(latitude, longitude) {
    let topographicElementsDB = await openDB("topographic-elements");

    let regions = await topographicElementsDB.getAll("regions");

    if (!regions || regions.length === 0) {
        return await axiosInstanceWithAuth.get('/api/bff/map/nearest-topographic-element-4/' + latitude + '/' + longitude);
    }

    let resultRegion = null;
    let closestTopographicElements4 = [];

    for (const region of regions) {
        // turf point
        if (booleanPointInPolygon([longitude, latitude], geojsonToTurf(region.transformed_polygon))) {
            resultRegion = region;
            break;
        }
    }

    if (!resultRegion) {
        return null;
    }

    let closestInsula = null;

    for (const insula of resultRegion.insula) {
        if (booleanPointInPolygon([longitude, latitude], geojsonToTurf(insula.transformed_polygon))) {
            resultRegion.insula = insula;
            break;
        } else {
            // calculate distance
            let insulaCentroid = centroid(geojsonToTurf(insula.transformed_polygon));
            let dist = distance([longitude, latitude], insulaCentroid);
            if (!closestInsula) {
                closestInsula = { insula, dist };
            } else {
                if (dist < closestInsula.dist) {
                    closestInsula = { insula, dist };
                }
            }
        }
    }

    // if resultRegion.insula is still an array, it means that the point is not inside any insula
    if (Array.isArray(resultRegion.insula)) {
        resultRegion.insula = closestInsula.insula;
        return resultRegion;
    }

    let closestUnity = null;

    for (const unity of resultRegion.insula.unity) {
        if (booleanPointInPolygon([longitude, latitude], geojsonToTurf(unity.transformed_polygon))) {
            resultRegion.insula.unity = unity;
            break;
        } else {
            // calculate distance
            let unityCentroid = centroid(geojsonToTurf(unity.transformed_polygon));
            let dist = distance([longitude, latitude], unityCentroid);
            if (!closestUnity) {
                closestUnity = { unity, dist };
            } else {
                if (dist < closestUnity.dist) {
                    closestUnity = { unity, dist };
                }
            }
        }
    }

    // if resultRegion.insula.unity is still an array, it means that the point is not inside any unity
    if (Array.isArray(resultRegion.insula.unity)) {
        resultRegion.insula.unity = closestUnity.unity;
        return resultRegion;
    }

    let closestEnvironment = null;

    for (const environment of resultRegion.insula.unity.environments) {
        if (environment.transformed_polygon &&
            booleanPointInPolygon([longitude, latitude], geojsonToTurf(environment.transformed_polygon))) {
            resultRegion.insula.unity.environment = environment;
        } else {
            // calculate distance
            let environmentCentroid = centroid(geojsonToTurf(environment.transformed_polygon));
            let dist = distance([longitude, latitude], environmentCentroid);
            if (!closestEnvironment) {
                closestEnvironment = { environment, dist };
            } else {
                if (dist < closestEnvironment.dist) {
                    closestEnvironment = { environment, dist };
                }
            }
        }
    }

    // if resultRegion.insula.unity.environment is still an array, it means that the point is not inside any environment
    if (Array.isArray(resultRegion.insula.unity.environment)) {
        resultRegion.insula.unity.environment = closestEnvironment.environment;
        return resultRegion;
    }

    for (const topographicElement4 of resultRegion.insula.unity.environment.topographic_element4s) {
        let te4Centroid = centroid(geojsonToTurf(topographicElement4.transformed_polygon));
        let dist = distance([longitude, latitude], te4Centroid);
        closestTopographicElements4.push({ topographicElement4, dist });
        closestTopographicElements4.sort((a, b) => a.distance - b.distance);
        if (closestTopographicElements4.length > 4) {
            closestTopographicElements4.pop();
        }
    }

    console.log(closestTopographicElements4);

    closestTopographicElements4 = closestTopographicElements4.map(te4 => te4.topographicElement4);

    resultRegion.insula.unity.environment.topographic_element4s = closestTopographicElements4;

    return resultRegion;
}