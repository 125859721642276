import { axiosInstanceWithAuth } from '@/utils/utils'
import { ref } from 'vue'
import { createTablesDB } from '../cacheServices'

const getTable = (apiName, readableName) => {
  const values = ref(null)
  const error = ref(null)

  const load = async () => {
    try {
      let res = await axiosInstanceWithAuth.get('/api/' + apiName)
      values.value = res.data.data.map(value => {
        return { value: value.id, label: value.name }
      })
    } catch (error) {
      // fetch from idb
      let db = await createTablesDB();

      values.value = await db.getAll(readableName);
    }
  }

  return { values, load, error }
}

export default getTable