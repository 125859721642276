<template>
    <div class="puntuale-form flex-col gap-20">
        <div class="ai-start jc-between">
            <div class="puntuale-title">
                <VIcon :src="require('@/assets/icons/form.svg')" />
                <h2>Nuovo monitoraggio<br />puntuale</h2>
            </div>
            <VButton :icon="require('@/assets/icons/cross.svg')" @click="close" />
        </div>
        <form v-if="!loading" class="form-box-puntuale grid" @submit.prevent="() => submit(false)">
            <div class="wrapper flex-col ai-start">
                <div class="block top"></div>
                <div class="box grid grid-2-col gap-10 bg-primary-color">
                    <InputWithLabel class="medium ai-center rounded" label="Data:" type="date" disabled
                        v-model="formData.date" />
                    <InputWithLabel class="medium ai-center rounded" label="Ora:" type="time" disabled
                        v-model="formData.time" />
                    <InputWithLabel class="medium ai-center grid-col-span" label="Progetto di riferimento:" :disabled="isSubmitting"
                    :disable-typing="true"
                        type="select" :options="projects" v-model="formData.project_id" :createUrl="'/api/project'" />
                    <VButton class="jc-between ref-topografic grid-col-span" :disabled="isSubmitting"
                        :label="topographic_element_4 ? 'Ref. topografico: ' + topographic_element_4.code : 'Ref. topografico:'"
                        :icon="require('@/assets/icons/geo-pin.svg')" :reverse="true"
                        @click="showRefTopSelector = true" />
                    <InputWithLabel class="medium ai-center grid-col-span rounded"
                        label="Tipologia referente topografico:" type="text" disabled
                        v-model="topographic_element_4_type" />
                    <InputWithLabel class="grid-col-span flex-col ai-start" label="Osservazioni:" type="textarea" :disabled="isSubmitting"
                        v-model="formData.observations" />
                    <InputWithLabel class="medium ai-center grid-col-span rounded" label="Segnalatore:" type="text"
                        disabled v-model="authStore.user.full_name" />
                    <div class="jc-between grid-col-span">
                        <VButton @click="addDegradation" class="gap-5 add-degradation"
                            :icon="require('@/assets/icons/add.svg')" label="Aggiungi degrado" :iconSize="25"
                            :disabled="noDegradations" :class="{ disabled: noDegradations }" />
                        <ColorCheckbox class="grid-col-span" label="Non sono presenti degradi" boxColor="black"
                            markColor="white" v-model="noDegradations" v-show="!formData.degradations.length" />
                    </div>
                    <TheAccordion class="grid-col-span degrado-accordion gap-5"
                        v-for="(degradation, i) in formData.degradations" :key="i" :item="degradation" showDeleteDegrado
                        @deleteDegrado="deleteDegrado(i)"
                        :name="degradation.degradation_type_id ? degradationTypes.find(el2 => el2.value == degradation.degradation_type_id).label : 'Nuovo degrado ' + (i + 1)">
                        <DegradoForm v-model="formData.degradations[i]" :specificElements="specificElements"
                            @update:model-value="() => submit(true)"
                            :topographic-element4-id="formData.topographic_element_4_id" v-if="degradation !== null" />
                    </TheAccordion>

                </div>
                <VLottie class="loading" v-if="loading" ref="animation" :src="require('@/assets/icons/loading.json')"
                    :autoplay="true" :loop="true" />
                <div class="block bottom"></div>
            </div>
            <div class="flex-col bg-primary-color jc-between gap-10 right-section">
                <div class="flex-col gap-10">
                    <VMap class="ref-top-map-puntuale" :center="MAP_CENTER" :customLayers="MAP_LAYERS"
                        :polygons="polygon ? [polygon] : [{ polygon: position }]" />
                    <div class="ref-top-details flex-col gap-5">
                        <p><strong>REGIONE: </strong>{{ topographic_element_4?.environment?.unity.insula.region.name ||
                            '-' }}</p>
                        <p><strong>INSULA: </strong>{{ topographic_element_4?.environment?.unity.insula.name || '-' }}
                        </p>
                        <p><strong>UNITÀ: </strong>{{ topographic_element_4?.environment?.unity.name || '-' }}</p>
                        <p><strong>AMBIENTE: </strong>{{ topographic_element_4?.environment?.name || '-' }}</p>
                    </div>
                </div>
                <div class="flex-col gap-10">
                    <div v-if="router.currentRoute.value.params.id && authStore.user.admin === 1"
                        class="buttons-secondary">
                        <VButton class="rounded w-full" label="valida la scheda" @click="isPopUpOpen = true"
                            :disabled="isSubmitting" />
                    </div>
                    <div class="buttons gap-10 grid grid-2-col" :style="{ opacity: isSubmitting ? 0.6 : 1 }">
                        <VButton class="rounded" label="Cancella" @click="close" :disabled="isSubmitting" />
                        <VButton type="submit" class="rounded" label="Salva" :disabled="isSubmitting" />
                    </div>
                </div>
            </div>
        </form>
        <Teleport to="#container">
            <RefTopPopUp v-if="showRefTopSelector" @close="showRefTopSelector = false" @save="updateDependencies" />
        </Teleport>
    </div>
    <Transition name="aggiungi" appear>
        <ValidatedSchedaPopUp v-if="isPopUpOpen" @validate="validatedScheda" @cancel="isPopUpOpen = false" />
    </Transition>
</template>
<script>
import { useRouter } from 'vue-router';
import InputWithLabel from '../InputWithLabel.vue';
import { useAuthStore } from '@/stores/auth';
import { onMounted, onUnmounted, ref, reactive } from 'vue';
import { axiosInstanceWithAuth, getDrawingBlob, getPhotoBlob } from '@/utils/utils';
import { MAP_CENTER, MAP_LAYERS } from '@/utils/polygon';
import TheAccordion from '../TheAccordion.vue';
import DegradoForm from '../DegradoForm.vue'
import RefTopPopUp from '../RefTopPopUp.vue';

import useMonitoring from '@/services/composables/useMonitoring'
import ColorCheckbox from '../ColorCheckbox.vue';
import ValidatedSchedaPopUp from '../ValidatedSchedaPopUp.vue';

import { useToast } from 'vue-toastification';
import getTable from '@/services/composables/getTable';
import { useSettingsStore } from '@/stores/settings';

export default {
    name: 'MonitoraggioPuntualeForm',
    components: {
        InputWithLabel,
        TheAccordion,
        DegradoForm,
        RefTopPopUp,
        ColorCheckbox,
        ValidatedSchedaPopUp
    },
    methods: {
        async submit(temp = false) {
            // create copy to avoid repeated watch triggers
            let degradations = this.formData.degradations.map(el => ({ ...el }))

            // checks
            if (degradations.length === 0 && !this.noDegradations && !temp) {
                this.toast.error('Devi inserire almeno un degrado o selezionare "Non sono presenti degradi"', {
                    position: 'bottom-center',
                })
                return
            }

            // if there is more than one degradation of the same type, return an error
            let degradationTypes = degradations.map(el => el.degradation_type_id)
            let uniqueDegradationTypes = [...new Set(degradationTypes)]
            if (degradationTypes.length !== uniqueDegradationTypes.length && !temp) {
                this.toast.error('ERRORE: non è possibile salvare una scheda che contenga nella stessa partizione più degradi dello stesso tipo', {
                    position: 'bottom-center',
                })
                return
            }

            this.isSubmitting = true && !temp // disable buttons only if not in temp mode

            // remove intensity and extension from the degradations
            degradations = await Promise.all(degradations.map(async el => {
                delete el?.intensity
                delete el?.extension

                if (el.files) {
                    el.files = el.files.map(async file => {
                        if (typeof file == 'number') {
                            let blob = await getPhotoBlob(file)
                            return blob.blob
                        }
                        return file
                    })

                    el.files = await Promise.all(el.files)
                }

                if (el.drawings) {
                    el.drawings = el.drawings.map(async drawing => {
                        if (typeof drawing == 'number') {
                            let blob = await getDrawingBlob(drawing)
                            return blob.blob
                        }
                        return drawing
                    })

                    el.drawings = await Promise.all(el.drawings)
                }

                return el
            }))

            // create a copy of formData to avoid repeated watch triggers
            let formData = { ...this.formData }

            formData.degradations = degradations

            console.log(this.router.currentRoute.value)

            // add id if present in route
            if (this.router.currentRoute.value.params.id) {
                formData.id = this.router.currentRoute.value.params.id
            }

            // add monitoringId if present in route
            if (this.router.currentRoute.value.params.monitoringId) {
                formData.monitoring_id = this.router.currentRoute.value.params.monitoringId
            }

            console.log(formData)

            this.addMonitoring(formData, 'punctual-monitoring', temp).then(id => {
                if (!temp) {
                    if (this.settingsStore.settings.offlineMode && window.ReactNativeWebView) {
                        this.toast.success('Monitoraggio salvato in locale. Verrà sincronizzato con il server quando il browser sarà online', {
                            position: 'bottom-center'
                        })
                        this.router.push('/manutenzione');
                    } else {
                        this.router.push('/manutenzione/monitoraggio/puntuale/' + id + '/mostra')
                    }
                }
            }).catch(err => {
                console.error(err)
                this.toast.error('Errore durante il salvataggio del monitoraggio: ' + err.message, {
                    position: 'bottom-center'
                })
            }).finally(() => {
                if (!temp) {
                    useMonitoring('punctual-monitoring').clearDataToSync()
                }
                this.isSubmitting = false;
            })
        },
        async validatedScheda() {
            await axiosInstanceWithAuth.post(`/api/bff/monitoring/validated/${this.monitoringId}`)
        }
    },
    watch: {
        formData: {
            handler: function () {
                if (!this.router.currentRoute.value.params.id) {
                    this.submit(true)
                }
            },
            deep: true
        }
    },
    computed: {
        polygon() { return this.topographic_element_4 ? this.topographic_element_4.transformed_polygon : null }
    },
    emits: ['close'],
    setup() {
        const { addMonitoring, getDataToSync } = useMonitoring('punctual-monitoring')
        const loading = ref(true)
        const router = useRouter();
        const authStore = useAuthStore();
        const settingsStore = useSettingsStore();
        const isSubmitting = ref(false);
        const monitoringId = ref();
        const isPopUpOpen = ref(false);

        // composables
        const { values: projects, load: loadProjects } = getTable('project', 'projects')
        const { values: degradationTypes, load: loadDegradationTypes } = getTable('degradation-type', 'degradationTypes')

        const formData = reactive({
            date: null,
            time: null,
            project_id: null,
            degradations: [],
            topographic_element_4_id: null,
        })

        const topographic_element_4_type = ref(null)
        const showRefTopSelector = ref(false)
        const noDegradations = ref(false)
        let date = new Date();

        // format date and time correctly
        let dateFormatter = new Intl.DateTimeFormat('it-IT', {
            timeZone: 'Europe/Rome',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        let timeFormatter = new Intl.DateTimeFormat('it-IT', {
            timeZone: 'Europe/Rome',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });

        let dateParts = dateFormatter.formatToParts(date).reduce((acc, part) => {
            if (part.type !== 'literal') acc[part.type] = part.value;
            return acc;
        }, {});
        let timeParts = timeFormatter.formatToParts(date).reduce((acc, part) => {
            if (part.type !== 'literal') acc[part.type] = part.value;
            return acc;
        }, {});

        // reconstruct the date and time strings
        let dateString = `${dateParts.year}-${dateParts.month}-${dateParts.day}`;
        let timeString = `${timeParts.hour}:${timeParts.minute}`;

        formData.date = dateString;
        formData.time = timeString;

        const position = ref(null)
        const topographic_element_4 = ref(null)
        const specificElements = ref([])

        const toast = useToast();

        const updateDependencies = async (value) => {
            if (!value) return

            showRefTopSelector.value = false

            formData.topographic_element_4_id = value.monitoring ? value.monitoring.topographic_element_4_id : value.topographic_element_id
            topographic_element_4_type.value = value.monitoring ? value.topographic_element4.type.name : value.type.name
            topographic_element_4.value = value.monitoring ? value.topographic_element4 : value

            let response = await axiosInstanceWithAuth.get('/api/bff/topographic-element-4-type/' +
                (value.monitoring ? value.topographic_element4.topographic_element_4_type_id : value.topographic_element_4_type_id) +
                '/specific-elements')
            specificElements.value = response.data.map(el => { return { label: el.name, value: el.id } })
        }

        const close = () => {
            if (router.currentRoute.value.params.id) {
                router.push({ name: 'mostra-monitoraggio-puntuale', params: { id: router.currentRoute.value.params.id } })
            } else {
                router.push({ path: router.currentRoute.value.meta.onClosePath })
            }
        }

        const addDegradation = () => {
            formData.degradations.push({})
        }

        const deleteDegrado = (i) => {
            // copy all the degradations from the index i+1 to the end to the index i and then remove the last element
            for (let j = i; j < formData.degradations.length - 1; j++) {
                formData.degradations[j] = formData.degradations[j + 1]
            }

            formData.degradations.pop()
        }

        onMounted(async () => {
            // disable pull to refresh
            document.body.style['overscrollBehaviorY'] = 'contain'

            await loadProjects()
            await loadDegradationTypes()

            // the existing monitoring is initialized
            if (router.currentRoute.value.params.id) {
                let res = (await axiosInstanceWithAuth.get(`/api/punctual-monitoring/${router.currentRoute.value.params.id}`)).data

                monitoringId.value = res.data.monitoring_id

                formData.project_id = res.data.project_id
                formData.topographic_element_4_id = res.data.monitoring.topographic_element_4_id
                formData.observations = res.data.observations

                // for each degradation, push an empty object and then set the data
                res.data.degradations.forEach((item) => {
                    formData.degradations.push({
                        ...item,
                        specificElementId: item.degradation_type.specific_elements[0].id
                    })
                })
                console.log(res.data.topographic_element4);
                topographic_element_4_type.value = res.data.topographic_element4.type.name
                await updateDependencies(res.data)
            } else {
                let dataToSync = await getDataToSync()

                if (dataToSync) {
                    formData.project_id = dataToSync.project_id
                    formData.topographic_element_4_id = dataToSync.topographic_element_4_id
                    formData.observations = dataToSync.observations

                    dataToSync.degradations.forEach((item) => {
                        formData.degradations.push(item)
                    })

                    // fetch the topographic element 4
                    if (dataToSync.topographic_element_4_id) {
                        let res = await axiosInstanceWithAuth.get('/api/bff/map/topographic-element-4/' + dataToSync.topographic_element_4_id)
                        console.log(res.data)
                        topographic_element_4_type.value = res.data.data.type.name
                        topographic_element_4.value = res.data.data
                        await updateDependencies(res.data.data)
                    }
                }
            }

            // handle the approfondisci function
            if (router.currentRoute.value.params.monitoringId) {
                let res = await axiosInstanceWithAuth.get(`/api/bff/monitoring/${router.currentRoute.value.params.monitoringId}/quickMonitoring`)
                position.value = res.data.position
            }

            loading.value = false
        })

        onUnmounted(() => {
            // restore pull-to-refresh
            document.body.style['overscrollBehaviorY'] = 'auto'
        })

        return {
            router, authStore, degradationTypes, projects, close, loading, addDegradation, topographic_element_4,
            showRefTopSelector, specificElements, addMonitoring, updateDependencies, topographic_element_4_type, position,
            noDegradations, toast, deleteDegrado, formData, settingsStore, isSubmitting,
            MAP_CENTER, MAP_LAYERS, monitoringId, isPopUpOpen
        }
    }
}
</script>
<style>
.puntuale-form {
    position: absolute;
    z-index: 4;
    height: 100%;
    width: 100%;
    background-color: var(--base-light-color);
    padding: 40px 40px 40px 60px;
    font-size: .875rem;
}

.puntuale-form .map {
    height: 250px !important;
}

.puntuale-title {
    gap: 10px;
    text-align: left;
    margin-left: 25px;
}

.form-box-puntuale {
    grid-template-columns: 7fr 3fr;
    gap: 13px;
    height: 100%;
    overflow: hidden;
}

.form-box-puntuale .v-button.disabled {
    opacity: 0.6;
}

.form-box-puntuale .color-checkbox {
    letter-spacing: unset;
    font-size: .875rem;
    font-weight: 500;
}

:is(.form-box-puntuale, .form-box-orizzontale)>div>div:not(.block) {
    padding: 25px 20px;
}

:is(.form-box-puntuale, .form-box-orizzontale)>.wrapper>div:not(.block) {
    padding: 0 20px;
}


:is(.form-box-puntuale, .form-box-orizzontale) .wrapper {
    overflow-y: scroll;
    max-height: 100%;
    align-self: flex-start;
    width: 100%;
    padding-right: 10px;
}

.add-degradation {
    margin: 5px;
    letter-spacing: 0.05rem;
}

:is(.form-box-puntuale, .form-box-orrizontale) .wrapper>div {
    width: 100%;
}

.puntuale-form .degrado-accordion .panel {
    background-color: var(--base-light-color);
}

.buttons .v-button {
    padding: 13px 15px;
    background-color: var(--base-dark-color);
    color: var(--primary-color);
}

.buttons-secondary .v-button {
    padding: 13px 15px;
    background-color: var(--base-mid-color);
    color: var(--primary-color);
    text-align: center;
    justify-content: center;
}

.right-section {
    overflow-y: auto;
    max-height: 100%;
}

.block {
    background: white;
    position: sticky;
    min-height: 25px;
    width: 100%;
    z-index: 5;
    padding: 0;
}

.top {
    top: 0;
}

.bottom {
    bottom: 0;
}
</style>