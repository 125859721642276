<template>
  <div class="grid grid-2-col gap-10 degrado-form">
    <InputWithLabel class="medium ai-center rounded" label="Data:" type="text" disabled v-model="readableDate" />
    <InputWithLabel class="medium ai-center rounded" label="Ora:" type="text" disabled v-model="readableTime" />
    <InputWithLabel class="medium ai-center rounded" label="Partizione:" type="select" :options="specificElements"
      @update:modelValue="updatePartition" v-model="degradation.specificElementId" />
    <InputWithLabel class="medium ai-center rounded" label="Descrizione:" type="select" :options="degradationTypes"
      @update:modelValue="updateDegradationType" v-model="degradation.degradation_type_id" />
    <InputWithLabel class="medium ai-center rounded" label="Codice degrado:" disabled v-model="degradationTypeCode" />
    <InputWithLabel class="medium ai-center rounded" label="Gravità:" type="text" disabled
      v-model="degradationTypeGravity" />
    <RadioButtonWithLabel :options="extensions" v-model="degradation.extension_id" label="ESTENSIONE" />
    <RadioButtonWithLabel :options="intensities" v-model="degradation.intensity_id" label="INTENSITÀ" />
    <InputWithLabel class="medium ai-center rounded" label="m/mq:" type="text" v-model="degradation.square_meters"
      @update:modelValue="updateValue('square_meters', $event)" />
    <InputWithLabel class="rounded medium" :label="'DR ' + dr" color="519ACF" type="text" disabled />
    <InputWithLabel class="grid-col-span flex-col ai-start" label="Annotazioni:" type="textarea" name="annotations"
      @update:modelValue="updateValue('annotations', $event)" v-model="annotations" />
    <UploadFiles class="grid-col-span" :maxSize="5" accept="jpg,jpeg" name="images" :existingFiles="existingFiles"
      :existingDrawings="props.modelValue.drawings" @change="updateValue('files', $event)" @remove="removeFile"
      @saveDraw="updateValue('drawings', $event)" />
  </div>
</template>
<script setup>
import { defineProps, ref, defineEmits, computed, reactive, onBeforeMount } from 'vue'
import InputWithLabel from './InputWithLabel.vue';
import UploadFiles from './UploadFiles.vue';
import RadioButtonWithLabel from './RadioButtonWithLabel.vue'

import { axiosInstanceWithAuth } from '@/utils/utils'

let props = defineProps({
  specificElements: {
    type: Array,
    default: () => []
  },
  topographicElement4Id: {
    type: [Number, String],
    default: null
  },
  modelValue: {
    type: Object,
    default: () => {
      return {
        degradation_type_id: null,
        extension_id: null,
        intensity_id: null,
        annotations: null,
        files: [],
        drawings: [],
      }
    }
  }
})

const time = props.modelValue?.created_at ? new Date(props.modelValue.created_at) : new Date()

// reactive data
const degradation = reactive(props.modelValue)
const degradationTypeCode = ref(null)
const degradationTypeGravityValue = ref(null)
const extensions = ref([])
const intensities = ref([])
const degradationTypes = ref([])
const degradationTypeGravity = ref(null)
const annotations = ref(null)
const existingFiles = ref([])
const deletedFiles = ref([])

// computed properties
const readableTime = computed(() =>     new Intl.DateTimeFormat('it-IT', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/Rome'
    }).format(time))
const readableDate = computed(() =>     new Intl.DateTimeFormat('it-IT', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/Rome'
    }).format(time))
const dr = computed(() => (!props.modelValue?.DR) ? 'non definito' : props.modelValue?.DR)

// methods
let emit = defineEmits(['update:modelValue'])

const updateValue = (field, value) => {
  console.log(field, value)
  emit('update:modelValue', {
    ...props.modelValue,
    [field]: value
  })
}

const removeFile = (file) => {
  if (typeof file == 'number') {
    // it is a file loaded from the indexedDB, so we have to remove it from existing files and from the indexedDB
    console.log(existingFiles.value, file)
    existingFiles.value = existingFiles.value.filter(el => el !== file)
    console.log(existingFiles.value)
  } else {
    deletedFiles.value.push(file)

    existingFiles.value = existingFiles.value.filter(el => el.id !== file.id)
  }

  console.log(props.modelValue.files.filter(el => {
    if (typeof file == 'number') {
      return el !== file
    } else {
      return el.id !== file.id
    }
  }))

  emit('update:modelValue', {
    ...props.modelValue,
    files: props.modelValue.files.filter(el => {
      if (typeof file == 'number') {
        return el !== file
      } else {
        return el.id !== file.id
      }
    }),
    deletedFiles: deletedFiles.value
  })
}

const updatePartition = (value) => {
  degradation.extension_id = null
  degradation.intensity_id = null
  degradation.degradation_type_id = null
  extensions.value = []
  intensities.value = []
  fetchDegradationTypes(value)
}

const fetchDegradationTypes = async (specificElementId) => {
  if (specificElementId) {

    let response = await axiosInstanceWithAuth.get('/api/bff/degradation-type/' + props.topographicElement4Id + '/' + specificElementId)

    degradationTypes.value = response.data.map(el => {
      return {
        value: el.id,
        label: el.name,
        code: el.code,
        extensions: el.extensions,
        intensities: el.intensities,
        degradation_gravities: el.degradation_gravities
      }
    })
  }
}

const updateDegradationType = (value, firstLoad = false) => {
  if (value) {
    let selectedDegradationType = degradationTypes.value.find(type => type.value == value)
    degradationTypeCode.value = selectedDegradationType.code

    intensities.value = selectedDegradationType.intensities.map(el => {
      return {
        value: el.id,
        label: el.name,
        numericValue: el.value
      }
    });

    extensions.value = selectedDegradationType.extensions.map(el => {
      return {
        value: el.id,
        label: el.name,
        numericValue: el.value
      }
    });

    degradationTypeGravity.value = selectedDegradationType.degradation_gravities[0].complete_name
    degradationTypeGravityValue.value = selectedDegradationType.degradation_gravities[0].value

    if (!firstLoad) {
      degradation.extension_id = null
      degradation.intensity_id = null
    }

    emit('update:modelValue', degradation)
    console.log(degradation)
  }
}

// lifecycle hooks
onBeforeMount(async () => {
  if (props.modelValue.degradation_type_id) {
    annotations.value = props.modelValue.annotations

    await fetchDegradationTypes(degradation.specificElementId ? degradation.specificElementId : degradation.degradation_type.specific_elements[0].id)
    degradation.degradation_type_id = props.modelValue.degradation_type_id
    degradation.extension_id = props.modelValue.extension_id
    degradation.intensity_id = props.modelValue.intensity_id
    existingFiles.value = props.modelValue.files
    updateDegradationType(degradation.degradation_type_id, true)
  }
})
</script>
<style>
.degrado-form {
  margin: 10px 0;
  padding: 15px;
}
</style>